







































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  ApplicationLanguageDto,
  DataDictionaryDto,
  LanguageTextDto,
} from "@/api/appService";
import CreateLanguageText from "@/views/admin/languageTexts/create.vue";

@Component({
  name: "LanguageTextList",
  components: {
    CreateLanguageText,
  },
})
export default class LanguageTextList extends Vue {
  sourceList: DataDictionaryDto[] = [];
  languageList: ApplicationLanguageDto[] = [];

  list = [] as LanguageTextDto[];
  total = 0;
  page = 1;
  pageSize = 10;
  key = "";
  value = "";

  form = {
    // source: "",
    key: "",
    languageName: "",
    value: "",
  };
  keyList = [] as string[];

  loading = true;
  listLoading = true;
  disabled = false;

  pageSizes = [] as number[];

  get pageSizeChoose() {
    if (this.pageSizes && this.pageSizes.length) {
      return this.pageSizes;
    } else {
      return [10, 20, 50, 100];
    }
  }

  async created() {
    // await this.fetchSourceList();
    await this.fetchLanguageList();
    await this.fetchData();
    this.loading = false;
    this.listLoading = false;
  }

  // async fetchSourceList() {
  //   await api.dataDictionary.getDataDictionaryListByKey({ key: "LanguageSources", maxResultCount: 65535 }).then(res => {
  //     this.sourceList = res.items!;
  //     if (this.sourceList && this.sourceList.length > 0) {
  //       this.form.source = this.sourceList[0].key!;
  //       this.fetchData()
  //     }
  //   });
  // }

  async fetchLanguageList() {
    await api.language.getAllLanguage().then((res) => {
      this.languageList = res ?? [];
    });
  }

  // 修改一页显示的条目
  handleSizeChange(e: number): void {
    this.pageSize = e;
    this.page = 1;
    this.fetchData();
  }

  // 更新当前页
  current_change(e: number): void {
    this.page = e;
    this.fetchData();
  }

  async fetchData() {
    this.listLoading = true;
    console.log("fetch data");
    let _this = this as any;
    await api.language
      .getAllLanguageTextTree({
        key: this.key,
        value: this.value,
        pageSize: this.pageSize,
        pageIndex: this.page,
        skipCount: (this.page - 1) * this.pageSize,
      })
      .then((res) => {
        _this.total = res.totalCount;
        _this.list = res.items ?? [];
        if (res.items && res.items.length > 0) {
          // let key = res[0].key
          // let index = 0;
          // _this.list.push({key: key,values: []})
          // res.forEach(item => {
          //   if(item.key === key) {
          //     _this.list[index].values.push({languageName: item.languageName, value: item.value})
          //   } else {
          //     key = item.key;
          //     index += 1;
          //     _this.list.push({key: item.key,values: []})
          //   }
          // })
          // debugger
          _this.keyList = res.items.map((item) => item.key) ?? [];
        }
        this.listLoading = false;
      });
  }

  handleCreate() {
    (this.$refs.createForm as any).form = this.form;
    (this.$refs.createForm as any).show = true;
  }

  handleDelete(row: any) {
    console.log("delete");
    api.language
      .deleteLanguageText({
        body: {
          // source: this.form.source,
          key: row.key,
        },
      })
      .then(() => {
        this.fetchData();
      });
  }

  handleUpdate(row: any, value: any) {
    this.disabled = true;
    this.form.key = row.key;
    this.form.languageName = value.languageName;
    this.form.value = value.value;
    api.language
      .updateLanguageTextValue({ body: this.form })
      .then(() => {
        this.fetchData();
        this.disabled = false;
      })
      .catch(() => {
        this.disabled = false;
      });
  }

  handleUpdateKey(row: any, value: any, index: number) {
    this.disabled = true;
    api.language
      .updateLanguageTextKey({
        body: {
          oldKey: this.keyList[index],
          newKey: value,
          // source: this.form.source,
          // languageName: row.values[0].languageName
        },
      })
      .then(() => {
        this.fetchData();
        this.disabled = false;
      })
      .catch(() => {
        this.disabled = false;
      });
  }

  jumpDataDictionaries() {
    this.$router.push({
      name: "dataDictionaries",
    });
  }

  getLanguageIndex(language: string, row: any) {
    let languageList = row.values?.map((item: any) => item.languageName);
    let index = languageList.indexOf(language);
    return index;
  }
}
